module.exports = {
  'selectLanguage': "Vyberte jazyk",
  "offering.heading": "Co nabízím?",
  'header.name': 'Monika Martinková',
  'header.tagline': 'Soudní překladatelka jazyka českého, německého a anglického',
  'home.intro1': 'Nabízím své dlouholeté zkušenosti, které jsem nabyla při překladech desítek tisíc stran. Podle posledního osvědčení vydaného Evropskou komisi jsem jen pro tento orgán přeložila <strong> 58&nbsp;000 stran</strong>, což pro představu odpovídá přibližně <strong>260 knihám</strong>. Další tisíce stran jsem přeložila pro jiné instituce či podniky v&nbsp;soukromém i&nbsp;veřejném sektoru.',
  'home.intro2': 'V porovnání s&nbsp;překladatelskými agenturami jsem schopna konkurovat cenou, přičemž sama zajišťuji celý proces překladu včetně korektur a&nbsp;stylistických úprav. Díky tomu zaručuji <strong> konzistentnost </strong> celého textu a&nbsp;<strong>pečlivé používání pojmů</strong>. Překlady dodávám vždy v&nbsp;dohodnutém termínu.',
  'offering.content': 'Nabízím zhotovování písemných překladů (nikoliv tlumočení), a&nbsp;to z&nbsp;<strong>angličtiny</strong>, <strong>němčiny</strong>, <strong>polštiny</strong> a&nbsp;<strong>slovenštiny</strong> do <strong>češtiny</strong> a&nbsp;z <strong>češtiny</strong> do <strong>angličtiny</strong> a&nbsp;<strong>němčiny</strong>.',
  'offering.legal': 'Zajišťuji soudně ověřené překlady. Provádím také korektury a&nbsp;revize textu, stylistické úpravy.',
  'offering.tools': 'Používám nástroje počítačem podporovaného překladu (CAT) - v&nbsp;současnosti <strong>SDL Trados Studio 2019</strong>.',
  'about.title': 'O mně',
  'about.part1': 'S překlady jsem začala na počátku 90. let v&nbsp;podniku, kam jsem nastoupila po absolvování vysoké školy. V&nbsp;té době se začaly rozvíjet obchodní vztahy s&nbsp;dodavateli ze zahraničí a&nbsp;objevily se i&nbsp;první zakázky financované z&nbsp;fondů EU. Jelikož jsem měla státní jazykové zkoušky, byla jsem pověřena zajišťováním překladů a&nbsp;tlumočení při jednáních. U&nbsp;překladů pro zaměstnavatele jsem zůstala i&nbsp;po nástupu na mateřskou dovolenou a&nbsp;postupně jsem začala služby poskytovat i&nbsp;dalším zákazníkům.',
  'about.part2': 'Jelikož o&nbsp;překlady byl velký zájem, rozhodla jsem se po skončení mateřské dovolené pro dráhu překladatelky „na volné noze“ a&nbsp;od roku 1995 vlastním živnostenský list.',
  'about.part3': 'Koncem 90. let jsem pak byla oslovena českou agenturou, která vyhrála výběrové řízení na překlady <em>acquis</em> EU před přistoupením ČR k&nbsp;Evropské unii, abych se podílela na vyhotovování českých překladů. S&nbsp;touto agenturou jsem pak spolupracovala až do roku 2003. V&nbsp;tomto roce byla vypsána první výběrová řízení orgánů EU, kterých jsem se vzhledem ke svým zkušenostem rozhodla zúčastnit. Od té doby pracuji téměř výhradně pro orgány EU, především pro Evropskou komisi.',
  'about.part4': 'V roce 2018 mě oslovila s&nbsp;nabídkou spolupráce i&nbsp;OECD (Organizace pro hospodářskou spolupráci a&nbsp;rozvoj), s&nbsp;níž jsem poté uzavřela smlouvu o&nbsp;poskytování překladatelských služeb.',
  'about.part5': 'A nedávno jsem uzavřela s&nbsp;nakladatelstvím Jan Melvil Publishing smlouvu na překlad své první knihy, což je práce, na kterou se velmi těším, jelikož to bude vyžadovat poněkud jiný přístup a&nbsp;dovednosti.',
  'references.title': 'Reference',
  'references.eu.title': 'Evropská komise, Generální ředitelství pro překlady',
  'references.eu.part1': 'Tento překlad byl ohodnocen jako „velmi dobrý“. Děkujeme za vysoce kvalitní práci.',
  'references.eu.part2': 'Velmi děkujeme za Váš překlad, s&nbsp;nímž jsme byli velmi spokojeni. Obzvláště oceňujeme jednotné citace z&nbsp;příslušných rozsudků Soudního dvora, nařízení atd. a&nbsp;Vaši schopnost výkladu.',
  'references.eu.part3': 'Považujeme (Váš překlad) za velmi precizní a&nbsp;oceňujeme jeho stylistickou úroveň, přesnost a&nbsp;správnost citací z&nbsp;rozhodnutí Soudního dvora a&nbsp;pokynů uvedených v&nbsp;textu.',
  'references.eu.part4': 'Osvědčení o&nbsp;počtu stran přeložených pro Evropskou komisi – ca <strong>58&nbsp;000&nbsp;stran</strong>, <strong>98&nbsp;%</strong> překladů s&nbsp;hodnocením <strong>velmi dobrý&nbsp;/&nbsp;dobrý</strong>',
  'references.presto.title': 'Presto, Překladatelské centrum, Praha',
  'references.presto.part1': 'Presto využívá překladatelských schopností Ing. Moniky Martinkové již dlouhá léta, přičemž v&nbsp;posledních dvou letech je jedním z&nbsp;překladatelů, kteří se v&nbsp;naší společnosti podílejí na překladech právních předpisů Evropských společenství pro Úřad vlády ČR. Její překlady z&nbsp;i do anglického jazyka jsou na velmi vysoké úrovni. (&hellip;)',
  'references.ingstav.title': 'Ingstav Ostrava, a.s.',
  'references.ingstav.part1': 'Překlady byly provedeny vždy velmi kvalitně a&nbsp;rychle.',
  'references.air.title': 'AIR-nemovitosti, Praha',
  'references.air.part1': 'Naše firma spolupracuje velmi úspěšně s&nbsp;pí Martinkovou již několik let. Naši zákazníci, např. HVB, ErsteBank či Deutsche bank jsou s&nbsp;překlady spokojeni. Co se týče termínů, vždy v&nbsp;domluveném čase.',
  'references.ci.title': 'Czech International, a.s., Havířov',
  'references.ci.part1': 'Paní ing. Martinková pracuje vždy velmi flexibilně a&nbsp;s velmi přesnou odbornou terminologií. Proto můžeme její profesionální služby doporučit.',
  'price.title': 'Jaká je cena?',
  'price.content': 'Cena se vždy odvíjí od cílového jazyka, náročnosti textu, termínu vyhotovení a&nbsp;rozsahu (v případě textů většího rozsahu či dlouhodobé spolupráce možnost slev).',
  'time.title': 'Kdy bude překlad hotov?',
  'time.content': 'Co se týká termínu odevzdání překladů, vždy se snažím vyhovět přání zákazníka, samozřejmě s&nbsp;přihlédnutím k&nbsp;rozsahu překladu a&nbsp;svým kapacitám. Dohodnutý termín <strong>vždy striktně</strong> dodržuji.',
  'specialization.title': 'V čem se vyznám?',
  'specialization.law': 'Právo (zejména obchodní a&nbsp;právo EU)',
  'specialization.economy': 'Veškeré ekonomické obory',
  'specialization.consumer': 'Spotřebitelské záležitosti',
  'specialization.environment': 'Životní prostředí',
  'specialization.social': 'Sociální oblast',
  'specialization.humanities': 'Humanitní a&nbsp;společenské obory',
  'specialization.technical': 'Odborné technické texty',
  'specialization.press': 'Tiskové články',
  'specialization.correspondence': 'Korespondence',
  'education.title': 'Jaké mám vzdělání?',
  'education.vsb': '<strong>Inženýr ekonomie</strong> - Vysoká škola báňská - Technická univerzita v&nbsp;Ostravě',
  'education.cuni': '<strong>Doplňkové studium pro soudní tlumočníky/překladatele</strong> - Právnická fakulta Univerzity Karlovy v&nbsp;Praze',
  'education.llm': '<strong>Executive Master of Laws – Commercial Law</strong> – Obchodní právo v&nbsp;kontextu práva EU',
  'contact.title': 'Kontakt',
  'contact.email': 'martinkova@translation-service.cz',
  'contact.mobile': 'Mobil: +420&nbsp;777&nbsp;157&nbsp;503',
  'contact.tel': 'Telefon: +420&nbsp;596&nbsp;410&nbsp;709',
  'contact.address': 'Monika Martinková<br />Vrchlického 6/1484<br />Havířov PSČ 736 01',
  'contact.ic': 'IČ: 60959495',
  'contact.tradeId': 'Číslo živnostenského listu č.j.: 99/637556/3169/Kr'
}
